<template>
  <div class="admin-feed-block CreatorStatistics">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale['creator_statistics']" />

    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          :icon="`dollar`"
          :label="`<strong>${$locale['total_subscriptions_sales']}</strong>`"
          :rightDesc="
            `<strong skeleton nowrap>$ ${Go.number_format(statistics.totalSubscriptions, 2)}</strong><small skeleton>${$locale['usd']}</small>`
          "
          @click="setQuery({ feed: 'creator-statistics-subscriptions', startDate, endDate, period: 'custom' })"
        />
        <LargeButton
          :icon="`coins`"
          :label="`<strong>${$locale['total_credits_sales']}</strong>`"
          :rightDesc="
            `<strong skeleton nowrap>$ ${Go.number_format(statistics.totalCreditsSales, 2)}</strong><small skeleton>${$locale['usd']}</small>`
          "
          @click="setQuery({ feed: 'creator-statistics-subscriptions', startDate, endDate, period: 'custom' })"
          prevent
        />
        <LargeButton
          :icon="`dollar`"
          :label="`<strong>${$locale['total_sales']}</strong>`"
          :rightDesc="`<strong skeleton nowrap gtotal-m>$ ${Go.number_format(totalSales, 2)}</strong><small skeleton>${$locale['usd']}</small>`"
          @click="setQuery({ feed: 'creator-statistics-subscriptions', startDate, endDate, period: 'custom' })"
          prevent
        />
      </div>
    </div>

    <CreatorStatisticsEarnBlock :statistics="statistics" />

    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          :icon="`coins`"
          :label="$locale['total_content']"
          :rightDesc="`<strong skeleton>${Go.number_format(statistics.totalContent, 2)}</strong><small skeleton>${$locale['credits']}</small>`"
          :desc="
            `<go-progress skeleton progress='${Go.getPercent(statistics.totalContent, statistics.totalCredits)}' 
            color='${$locale.colors['content']}'></go-progress>`
          "
          @click="setQuery({ feed: 'creator-statistics-content-details', startDate, endDate, period: 'custom', type: 'unlock' })"
        />
        <LargeButton
          :icon="`gift`"
          :label="$locale['total_gifts']"
          :rightDesc="`<strong skeleton>${Go.number_format(statistics.totalGifts, 2)}</strong><small skeleton>${$locale['credits']}</small>`"
          :desc="
            `<go-progress skeleton progress='${Go.getPercent(statistics.totalGifts, statistics.totalCredits)}' 
            color='${$locale.colors['gifts']}'></go-progress>`
          "
          @click="setQuery({ feed: 'creator-statistics-content-details', startDate, endDate, period: 'custom', type: 'gift' })"
        />
        <LargeButton
          :icon="`flower`"
          :label="$locale['total_flowers']"
          :rightDesc="`<strong skeleton>${Go.number_format(statistics.totalFlowers, 2)}</strong><small skeleton>${$locale['credits']}</small>`"
          :desc="
            `<go-progress skeleton progress='${Go.getPercent(statistics.totalFlowers, statistics.totalCredits)}' 
            color='${$locale.colors['flowers']}'></go-progress>`
          "
          @click="setQuery({ feed: 'creator-statistics-content-details', startDate, endDate, period: 'custom', type: 'flower' })"
        />
        <LargeButton
          :icon="`coins`"
          :label="$locale['total_tips']"
          :rightDesc="`<strong skeleton>${Go.number_format(statistics.totalTips, 2)}</strong><small skeleton>${$locale['credits']}</small>`"
          :desc="
            `<go-progress skeleton progress='${Go.getPercent(statistics.totalTips, statistics.totalCredits)}' 
            color='${$locale.colors['tips']}'></go-progress>`
          "
          @click="setQuery({ feed: 'creator-statistics-content-details', startDate, endDate, period: 'custom', type: 'tip' })"
        />
      </div>
    </div>

    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`" v-if="statistics.totalCredits">
      <div class="ReportInfo">
        <AdminStatisticsSalesChart :statistics="statistics" />
      </div>
    </div>
    <Spacer num=".5" />
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "@/components/user/UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  components: {
    AdminStatisticsFilter: () => import("../admin/AdminStatisticsFilter.vue"),
    AdminStatisticsSalesChart: () => import("../admin/AdminStatisticsSalesChart.vue"),
    CreatorStatisticsEarnBlock: () => import("./CreatorStatisticsEarnBlock.vue"),
  },
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/creator/statistics-sales`,
      storeId: "my_statistics",
      items: [],
      statistics: { page: 1, percentage: 0 },
      limit: 20,
    };
  },
  computed: {
    totalSales: function() {
      return Number(this.statistics.totalSubscriptions) + Number(this.statistics.totalCreditsSales);
    },
  },
};
</script>
